<!--
// nuxt-ui/components/context-shared/MainGradientDivider.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

const { isKings, isQueens } = useWebContext();
type Props = {
  height?: string;
};

withDefaults(defineProps<Props>(), {
  height: "h-6",
});
</script>

<template>
  <div v-if="isKings" :class="['main-gradient-kings', height]"><span></span></div>
  <div v-if="isQueens" :class="['main-gradient-queens', height]"><span></span></div>
</template>

<style scoped>
.main-gradient-kings {
  background: linear-gradient(270deg, #f4af23 0%, #e35205 100%);
}

.main-gradient-queens {
  background: linear-gradient(90deg, #008ca0 0%, #2e1a47 100%);
}
</style>
